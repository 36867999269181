import resolveCtaUrl from '@tap-bio/core/utils/resolve-cta-url';
import { withHandlers } from 'lib/recompose';

export default withHandlers({
  handleCtaClick: (props) => (altCta) => {
    const { cta, id: cardId, sendCardClick } = props;
    const { id: accountId } = props.account;
    const { id: ctaId, url } = cta || altCta;
    sendCardClick(accountId, cardId, ctaId, url);

    // Do nothing if this is a copy CTA type
    if (url.startsWith('copy:')) return;

    window.location = resolveCtaUrl(url);
  },
});
